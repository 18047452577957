import { IDataValueId } from '@tapestry/shared/graphql';

export const calculatePercentage = (a: number, b: number): number => {
  return a / b;
};

export const getMaxValue = (data: IDataValueId[] | undefined) => {
  if (!data) return 0;
  const maxValue = Math.max(...data.map((item) => item.value || 0));

  return maxValue;
};
