import { SUPPORT_EMAIL } from '@tapestry/shared/constants';
import { ExclamationCircleIcon } from '@tapestry/shared/icons';
import { Card } from '@tapestry/weave';
import { FC } from 'react';

export const NoResultsWarning: FC = () => (
  <Card bgColor="bg-gray-100">
    <div className="flex">
      <ExclamationCircleIcon className="mt-1 h-6 w-auto flex-shrink-0" />
      <p className="ml-4 text-left">
        We're sorry, but we couldn't retrieve your data at this time. <br />
        This could be because there's no data available, or there might be an
        issue with our servers. <br />
        If you believe this to be an error, contact{' '}
        <a href={`mailto:${SUPPORT_EMAIL}`} className="text-primary underline">
          support
        </a>
        .
      </p>
    </div>
  </Card>
);
