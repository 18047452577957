import Skeleton from 'react-loading-skeleton';

export const HorizontalBarLoadingState = () => {
  return (
    <div className="space-y-2">
      {new Array(5).fill(null).map((_, index) => (
        <div key={index} className="flex items-center justify-between">
          <div className="mr-6 w-full">
            <Skeleton height="2rem" style={{ width: `${100 - 20 * index}%` }} />
            <Skeleton height="1rem" style={{ width: `${90 - 20 * index}%` }} />
          </div>

          <Skeleton height="2rem" circle width="2rem" />
        </div>
      ))}
    </div>
  );
};
