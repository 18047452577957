import React, { useEffect, useState, FC } from 'react';
import { AnalyseButton, Card, ResponsiveDropdownMenu } from '@tapestry/weave';
import Skeleton from 'react-loading-skeleton';
import {
  SearchIcon,
  SortDownIcon,
  StockLevelIcon,
} from '@tapestry/shared/icons';
import { ROUTE_PATHS } from '@tapestry/shared/constants';
import { ErrorBoundary, useToast, useUIContext } from '@tapestry/shared/client';
import {
  useLongLoading,
  useModal,
  useSortMenu,
  useURIReturnPath,
} from '@tapestry/shared/hooks';
import {
  capitaliseString,
  parseToCurrencyString,
  safeJSONParse,
} from '@tapestry/shared/utils';
import isEmpty from 'lodash/isEmpty';
import {
  HeartbeatVisualizationQueriesBaseVariables,
  IMetric,
  Visualization,
} from '@tapestry/types';
import {
  useGetHeartbeatBarChartData,
  useGetHeartbeatBarChartPaginatedData,
} from '@tapestry/shared/graphql';
import { calculatePercentage, getMaxValue } from './utils';
import { VisualizationSearchModal } from '@tapestry/shared/visualisation';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Link from 'next/link';
import { useDebounce } from 'use-debounce';
import { captureException } from '@sentry/nextjs';
import { ChartErrorView } from '@tapestry/shared/components';
import { NoResultsWarning } from './HorizontalBarChartVisualisationEmptyState';
import { HorizontalBarLoadingState } from './HorizontalBarChartVisualisationLoadingState';

interface HorizontalChartQueryVariables
  extends HeartbeatVisualizationQueriesBaseVariables {
  scopeType: string;
  threadType: string;
}

interface IHorizontalBarChartVisualisationProps {
  title: string;
  queryVariables: HorizontalChartQueryVariables;
  unit: IMetric['unit'];
  isLoading?: boolean;
  readOnly?: boolean;
  visualisation: Visualization;
}

const SEARCH_QUERY_DEBOUNCE = 250;
const SEARCH_RESULT_LIMIT = 20;
const INITIAL_PAGE_NO = 1;

const HorizontalBarChartVisualisation: FC<
  IHorizontalBarChartVisualisationProps
> = ({ title, queryVariables, unit, readOnly, visualisation }) => {
  /**
   * Hooks
   */
  const { addToast } = useToast();
  const [page, setPage] = useState(1);
  const {
    items,
    state: { sortBy },
  } = useSortMenu();

  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, SEARCH_QUERY_DEBOUNCE);
  const returnTo = useURIReturnPath();

  // UI
  const searchModalState = useModal();
  const [
    {
      threadTypeThemeColors: {
        backgroundColor,
        backgroundColorHover,
        textColor,
        textColorHover,
      },
    },
  ] = useUIContext();

  const { horizontalChartAnalyseButton: hasHorizontalChartAnalyseButtonFlag } =
    useFlags();

  const filters = safeJSONParse(queryVariables?.filters);
  const hasId = filters?.groupId || filters?.shopId;
  const baseQueryVariables = React.useMemo(
    () => ({
      ...queryVariables,
      search: '',
      limit: 5,
      page: INITIAL_PAGE_NO,
      sortBy,
    }),
    [queryVariables, sortBy]
  );

  const { data: chartData, loading: isChartDataLoading } =
    useGetHeartbeatBarChartData({
      skip: !hasId,
      variables: baseQueryVariables,
      onError: () => {
        addToast({
          type: 'error',
          content: `An error has occured whilst retrieving the data for ${queryVariables.scopeType}. `,
        });
      },
      onCompleted: (data) => {
        const initialChartDataResults = data?.heartbeatChartBarV3?.data;

        if (isEmpty(initialChartDataResults)) {
          captureException('No data found for this query', {
            level: 'info',
            tags: { visualisation },
          });
        }
      },
    });

  const {
    data: modalData,
    loading: isModalDataLoading,
    refetch: refetchModalData,
  } = useGetHeartbeatBarChartPaginatedData({
    skip: !hasId || !chartData,
    variables: {
      ...baseQueryVariables,
      limit: SEARCH_RESULT_LIMIT,
      sortBy,
    },
    onError: () => {
      addToast({
        type: 'error',
        content: `An error has occured whilst retrieving the data for ${queryVariables.scopeType}. `,
      });
    },
  });

  const { loadingMessage } = useLongLoading({
    mode: 'interval',
    isLoading: isChartDataLoading,
  });

  const initialChartDataResults = chartData?.heartbeatChartBarV3?.data;
  const maxValue = getMaxValue(initialChartDataResults);
  const dataList = modalData?.heartbeatChartBarV3?.data || [];
  const pagination = modalData?.heartbeatChartBarV3?.pagination;

  /**
   * Const
   */
  const handleSortbyChange = (sortBy: string) => {
    setPage(INITIAL_PAGE_NO);
    refetchModalData({ sortBy, page: INITIAL_PAGE_NO });
  };

  const handleLinkWithPermissions = (id) => {
    return hasHorizontalChartAnalyseButtonFlag
      ? ROUTE_PATHS.build.threadSummary(id, {
          returnTo,
        })
      : ROUTE_PATHS.build.heartbeat({
          threadType: queryVariables.threadType,
          qp: `${queryVariables.threadType}Id=${id || ''}`,
        });
  };

  const notifyOfReadOnlyState = () => {
    alert('Coming Soon...');
  };

  const handlePreviousePage = (page?: number) => {
    setPage((currentPage) => {
      const newPage = page || currentPage - 1;
      const _page = newPage > INITIAL_PAGE_NO ? newPage : INITIAL_PAGE_NO;

      refetchModalData({ page: _page });

      return _page;
    });
  };

  const handleNextPage = (page?: number) => {
    setPage((currentPage) => {
      const newPage = page || currentPage + 1;

      refetchModalData({ page: newPage });

      return newPage;
    });
  };

  useEffect(() => {
    if (!searchModalState.isOpen) {
      return;
    }

    refetchModalData({
      ...baseQueryVariables,
      search: debouncedSearch,
      limit: SEARCH_RESULT_LIMIT,
    });
  }, [
    baseQueryVariables,
    debouncedSearch,
    refetchModalData,
    searchModalState.isOpen,
  ]);

  return (
    <ErrorBoundary errorView={<ChartErrorView />}>
      <Card spacing="medium" align="top">
        <header className="flex w-full flex-row items-center justify-between">
          <div className="text-orange flex items-center ">
            <StockLevelIcon fillColor="currentColor" className="size-5" />

            <h2 className="ml-2 text-lg font-medium">
              {title || <Skeleton />}
            </h2>
          </div>

          <div className="ml-4 flex items-center justify-end space-x-3">
            <button
              title={`Search ${title}`}
              className="h-8 rounded-full bg-gray-200 p-2 hover:bg-gray-300 focus:bg-gray-300 focus:outline-none sm:h-6 sm:p-1"
              onClick={searchModalState.open}
              onKeyDown={({ key }) => {
                if (key === 'Enter') {
                  searchModalState.open();
                }
              }}
            >
              <SearchIcon />
            </button>

            <ResponsiveDropdownMenu>
              <ResponsiveDropdownMenu.Button
                title={`Sort ${title}`}
                className="h-8 rounded-full bg-gray-200 p-2 hover:bg-gray-300 focus:bg-gray-300 focus:outline-none sm:h-6 sm:p-1"
              >
                <span className="sr-only">Sort {title}</span>
                <SortDownIcon />
              </ResponsiveDropdownMenu.Button>

              <ResponsiveDropdownMenu.Menu>
                {items.map((item) => (
                  <ResponsiveDropdownMenu.StyledMenuItem
                    key={item.label}
                    label={item.label}
                    onClick={item.onclick}
                    isActive={item.id === sortBy}
                  />
                ))}
              </ResponsiveDropdownMenu.Menu>
            </ResponsiveDropdownMenu>
          </div>
        </header>

        {isEmpty(initialChartDataResults) && !isChartDataLoading ? (
          <NoResultsWarning />
        ) : null}

        {isEmpty(initialChartDataResults) && isChartDataLoading ? (
          <ul className="my-4 h-full w-full space-y-2 sm:space-y-4">
            <li className="relative block">
              <HorizontalBarLoadingState />
              {loadingMessage ? (
                <p className="absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform text-center font-medium tracking-wide text-gray-700">
                  {loadingMessage}
                </p>
              ) : null}
            </li>
          </ul>
        ) : null}

        {!isEmpty(initialChartDataResults) && !isChartDataLoading ? (
          <>
            <ul className="my-4 w-full space-y-2">
              {initialChartDataResults?.map(({ id, name, value }) => {
                const valPercentage =
                  calculatePercentage(value || 0, maxValue) * 100;

                return (
                  <li
                    key={`${id}${name}`}
                    className="flex w-full items-center justify-between gap-4"
                  >
                    <Link
                      href={handleLinkWithPermissions(id)}
                      className={`relactive group flex-grow cursor-pointer overflow-x-auto focus:outline-none ${
                        readOnly ? 'cursor-auto' : ''
                      }`}
                      onClick={(e) => {
                        if (!hasHorizontalChartAnalyseButtonFlag && readOnly) {
                          e.preventDefault();
                          notifyOfReadOnlyState();
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          if (
                            !hasHorizontalChartAnalyseButtonFlag &&
                            readOnly
                          ) {
                            e.preventDefault();
                            notifyOfReadOnlyState();
                          }
                        }
                      }}
                    >
                      <h3
                        title={name || ''}
                        className={`mb-1 truncate text-sm transition-colors duration-150 ease-out group-focus:outline-none ${
                          textColor ?? 'text-gray-text'
                        } group-hover:${textColorHover} group-focus:${textColorHover}`}
                      >
                        {capitaliseString(name)}
                      </h3>

                      {/* the bars */}
                      <div
                        className="flex flex-row flex-nowrap items-center"
                        style={{
                          width: `${valPercentage}%`,
                          minWidth: '40%',
                        }}
                      >
                        <div
                          className={`h-6 rounded-md transition-colors duration-150 ease-out group-focus:outline-none ${
                            valPercentage < 1 ? 'w-1' : 'w-full'
                          } ${
                            backgroundColor ?? 'bg-primary'
                          } group-focus:${backgroundColorHover} group-hover:${backgroundColorHover}`}
                        />
                      </div>

                      <div className="mt-1 text-base font-bold text-black">
                        {parseToCurrencyString(
                          value || 0,
                          unit?.symbol,
                          unit?.position
                        )}
                      </div>
                    </Link>

                    {hasHorizontalChartAnalyseButtonFlag ? (
                      <>
                        {/* phone */}
                        <Link
                          title={`View ${name} in Heartbeat`}
                          href={ROUTE_PATHS.build.heartbeat({
                            threadType: queryVariables.threadType,
                            qp: `${queryVariables.threadType}Id=${id || ''}`,
                          })}
                          className="lg:hidden"
                        >
                          <AnalyseButton variant="icon" />
                          <span className="sr-only">
                            View {name} in heartbeat
                          </span>
                        </Link>
                        {/* laptop and up */}
                        <Link
                          title={`View ${name} in Heartbeat`}
                          href={ROUTE_PATHS.build.heartbeat({
                            threadType: queryVariables.threadType,
                            qp: `${queryVariables.threadType}Id=${id || ''}`,
                          })}
                          className="hidden lg:block"
                        >
                          <AnalyseButton variant="compact" />
                          <span className="sr-only">
                            View {name} in heartbeat
                          </span>
                        </Link>
                      </>
                    ) : null}
                  </li>
                );
              })}
            </ul>

            <footer className="w-full flex-grow place-content-center text-center">
              <button
                type="button"
                disabled={isChartDataLoading}
                onClick={searchModalState.open}
                className="focus:text-primary hover:text-primary ease active:text-primary-dark text-base font-medium duration-150 focus:outline-none"
              >
                See All Products
              </button>
            </footer>
          </>
        ) : null}

        {searchModalState.isOpen && pagination && (
          <VisualizationSearchModal
            title={title}
            defaultSortBy={sortBy}
            data={dataList}
            isLoading={isModalDataLoading}
            unit={unit}
            onInputChange={(value) => {
              setPage(1);
              setSearch(value);
            }}
            onSortbyChange={handleSortbyChange}
            modalState={searchModalState}
            onLinkClicked={(item) => handleLinkWithPermissions(item?.id)}
            readOnly={readOnly}
            withPagination={{
              showBoundaryPages: true,
              boundaryPagesAmount: 2,
              showFirstAndLastPage: true,
              lastPage: pagination.totalPages,
              currentPage: page,
              isLastPage: page === pagination.totalPages,
              onPrev: handlePreviousePage,
              onPrevWithPageNumber: handlePreviousePage,
              onNext: handleNextPage,
              onNextWithPageNumber: handleNextPage,
            }}
          />
        )}
      </Card>
    </ErrorBoundary>
  );
};

export { HorizontalBarChartVisualisation };
export default HorizontalBarChartVisualisation;
