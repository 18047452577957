import { useProfile, useWatchForShopId } from '@tapestry/shared/hooks';
import Heartbeat from '@tapestry/shared/applets/heartbeat';
import { GetStaticProps } from 'next';
import Head from 'next/head';
import { useWatchForURLDevMode } from '@tapestry/shared/hooks';
import { useRouter } from 'next/router';
import { ROUTE_PATHS } from '@tapestry/shared/constants';
import { RetailDashboard } from '@retail/dashboard';
import { useFlags } from 'launchdarkly-react-client-sdk';

export function HomeDashboard() {
  useWatchForShopId();
  useWatchForURLDevMode();
  const [profile] = useProfile();
  const router = useRouter();
  const { retailDashboard } = useFlags();

  if (!profile) {
    return null;
  }

  const hasActiveScope = profile.active_shop_scope?.id;

  if (!hasActiveScope && router.isReady) {
    router.push(ROUTE_PATHS.selector);
  }

  if (!retailDashboard) {
    return (
      <>
        <Head>
          <title>tapestry.ai | Retail+ - Home</title>
          <meta
            name="description"
            content="Displays Real time data for your shop or group over various measures"
          />
        </Head>

        <Heartbeat />
      </>
    );
  }

  return (
    <>
      <Head>
        <title>tapestry.ai | Retail+ - Home</title>
        <meta
          name="description"
          content="See your business metrics at a glance"
        />
      </Head>

      <RetailDashboard />
    </>
  );
}

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      noShadowOnNavbar: true,
    },
  };
};

export default HomeDashboard;
