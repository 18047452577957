import { ChartErrorView } from '@tapestry/shared/components';
import { useGetMeasureSummary } from '@tapestry/shared/graphql';
import {
  ArrowRightIcon,
  HeartbeatIcon,
  TotalSales,
} from '@tapestry/shared/icons';
import { dateTime, parseToCurrencyString } from '@tapestry/shared/utils';
import { IMetric } from '@tapestry/types';
import { Card } from '@tapestry/weave';
import { ErrorBoundary } from '@tapestry/shared/client';
import { FC } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { twMerge } from 'tailwind-merge';
import { ROUTE_PATHS } from '@tapestry/shared/constants';
import Link from 'next/link';
import { AutoTextSize } from 'auto-text-size';

const getTextColorFromDelta = (dataDelta: number) => {
  if (dataDelta > 0) {
    return 'text-success';
  }

  if (dataDelta < 0) {
    return 'text-danger';
  }

  return 'text-white';
};

/**
 * TODO: Delete once he's seen the light
 */
const ButtonToMakeChrisHappy: React.FC = () => (
  <span
    className={twMerge(
      'flex items-center justify-center rounded-full border-2 bg-black transition-colors duration-150 ease-in',
      'h-8 px-3 py-2',

      'border-primary text-primary hover:bg-primary focus:bg-primary hover:text-white focus:text-white focus:outline-none'
    )}
  >
    <HeartbeatIcon fillColor="currentColor" />
    <span className="text-bold ml-1 inline-block text-xs">
      See Full Analysis
    </span>
  </span>
);

interface MeasureVisualisationProps {
  measure: IMetric;
  dateRange: {
    startDate: string;
    endDate: string;
  };
  /**
   * the shop or group id key value pair
   */
  scope: Record<string, string | null | undefined>;
}

const lastWeekAtThisTime = dateTime.now().subtract(1, 'week');

const MeasureVisualisation: FC<MeasureVisualisationProps> = ({
  measure: { title, unit, key },
  dateRange,
  scope,
}) => {
  const variables = {
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    measure: key,
    filters: scope,
  };

  const hasValidScopeId =
    variables?.filters.groupId || variables?.filters.shopId;

  const { data, loading, error } = useGetMeasureSummary({
    skip: !hasValidScopeId,
    variables,
  });
  const mainDataPoint = data?.heartbeatHomeSummary?.value || 0;

  // * last week
  const comparisonStartDate = dateTime
    .parse(dateRange.startDate)
    .subtract(1, 'week');
  const displayComparisonStartDate = comparisonStartDate.format('DD/MM');
  const displayComparisonEndDate = lastWeekAtThisTime.format('DD/MM');

  const {
    data: comparisonData,
    loading: isLoadingComparisonData,
    error: hasComparisonError,
  } = useGetMeasureSummary({
    skip: !hasValidScopeId,
    variables: {
      ...variables,
      startDate: comparisonStartDate.format(),
      endDate: lastWeekAtThisTime.format(),
    },
  });
  const comparisonDataPoint = comparisonData?.heartbeatHomeSummary?.value || 0;
  const isLoadingComparison = loading || isLoadingComparisonData;

  const dataDelta = mainDataPoint - comparisonDataPoint;

  const textColor = getTextColorFromDelta(dataDelta);

  return (
    <ErrorBoundary errorView={<ChartErrorView />}>
      <SkeletonTheme highlightColor="#F28A14" baseColor="var(--color-primary)">
        <Card
          bgColor="bg-black"
          spacing="medium"
          className="text-base font-medium text-white"
        >
          <div className="text-orange flex flex-row items-center">
            <TotalSales fillColor="currentColor" className="size-6" />
            <h2 className="ml-2 text-lg">{title}</h2>
          </div>
          {loading && (
            <span className="w-full">
              <Skeleton height={28} className="my-3" />
            </span>
          )}
          {!loading && error && (
            <p role="alert" className="text-danger my-2 text-2xl capitalize">
              Error loading data
            </p>
          )}
          {!loading && !error && (
            <div className="my-2 w-full flex-col capitalize">
              <AutoTextSize as="div" mode="oneline" maxFontSizePx={26}>
                {parseToCurrencyString(
                  mainDataPoint,
                  unit?.symbol,
                  unit?.position
                )}
                {unit?.symbol === '$' && <span className="ml-2">AUD</span>}
              </AutoTextSize>
              <p className="-mt-1 text-xs tracking-wide">Current Week</p>
            </div>
          )}
          <div className="flex w-full flex-row items-center gap-4 overflow-hidden">
            {isLoadingComparison ? (
              <Skeleton width={24} height={24} circle />
            ) : (
              <ArrowRightIcon
                className={twMerge(
                  'h-6 w-auto flex-shrink-0',
                  dataDelta >= 0 ? 'rotate-270' : 'rotate-90',
                  textColor
                )}
                fillColor="currentColor"
              />
            )}

            <div className="flex-1 flex-col overflow-hidden">
              {isLoadingComparison && (
                <span className="w-full">
                  <Skeleton height={16} />
                </span>
              )}

              {!isLoadingComparison && hasComparisonError ? (
                <p role="alert" className="text-danger">
                  Error loading comparison data
                </p>
              ) : null}

              {!isLoadingComparison && !hasComparisonError ? (
                <AutoTextSize as="div" maxFontSizePx={16} className={textColor}>
                  {parseToCurrencyString(
                    dataDelta,
                    unit?.symbol,
                    unit?.position
                  )}
                  {unit?.symbol === '$' && <span className="ml-2">AUD</span>}
                </AutoTextSize>
              ) : null}

              <p className="w-full text-xs tracking-wide">
                {isLoadingComparison ? (
                  <span className="w-full">
                    <Skeleton />
                  </span>
                ) : (
                  <>
                    Comparison Period:{' '}
                    <time dateTime={comparisonStartDate.format()}>
                      {displayComparisonStartDate}
                    </time>{' '}
                    &#8208;{' '}
                    <time dateTime={lastWeekAtThisTime.format()}>
                      {displayComparisonEndDate}
                    </time>
                  </>
                )}
              </p>
            </div>
          </div>
          {/* note: realtime default date range is current week as is this widget, might need change in future */}
          <Link
            href={ROUTE_PATHS.build.heartbeat({
              qp: encodeURI(`slug=${key}`),
            })}
            className="mt-4 w-full text-center text-lg capitalize"
          >
            <ButtonToMakeChrisHappy />
          </Link>
        </Card>
      </SkeletonTheme>
    </ErrorBoundary>
  );
};

export default MeasureVisualisation;
