import { ROUTE_PATHS } from '@tapestry/shared/constants';
import { TickIcon } from '@tapestry/shared/icons';
import { Card, Link } from '@tapestry/weave';
import isEmpty from 'lodash/isEmpty';
import { FC } from 'react';
import { ErrorBoundary } from '@tapestry/shared/client';
import { ChartErrorView } from '@tapestry/shared/components';
import {
  TaskList,
  TasksListEmptyState,
  TaskListLoadingState,
  useGetTasks,
  POLLING_INTERVAL,
  GET_TASKS_DEFAULT_VARIABLES,
} from '@tapestry/shared/applets/tasks';

export const TasksListVisualisation: FC = () => {
  const {
    data: { tasks },
    isLoadingTasks,
    error,
  } = useGetTasks({
    pollInterval: POLLING_INTERVAL,
    skipPollAttempt: () => window.document.hidden,
    variables: GET_TASKS_DEFAULT_VARIABLES,
  });

  return (
    <ErrorBoundary errorView={<ChartErrorView />}>
      <Card spacing="medium" align="top" className="h-full max-h-full w-full">
        <header className="text-primary flex w-full items-center">
          <TickIcon fillColor="currentColor" className="size-5" />

          <h2 className="ml-2 text-lg font-medium">My Tasks</h2>
        </header>

        <div className="w-full flex-grow py-8">
          {isEmpty(tasks) && !isLoadingTasks && !error ? (
            <div className="flex h-full w-full items-center justify-center">
              <TasksListEmptyState />
            </div>
          ) : null}

          <ul className="@container tasks-list-visualisation group mb-4 flex w-full flex-col gap-2">
            {isEmpty(tasks) && isLoadingTasks && !error ? (
              <TaskListLoadingState />
            ) : null}

            {!isEmpty(tasks) && !isLoadingTasks && !error ? (
              <TaskList tasks={tasks} />
            ) : null}
          </ul>
        </div>
        <footer className="w-full place-content-center text-center">
          <Link
            href={ROUTE_PATHS.tasks}
            className="focus:text-primary hover:text-primary ease active:text-primary-dark text-base font-medium duration-150 focus:outline-none"
          >
            See All Tasks
          </Link>
        </footer>
      </Card>
    </ErrorBoundary>
  );
};
